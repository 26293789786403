<template>
  <form @submit.prevent="submitForm" class="registration">
    <fieldset class="inputs">
      <div class="input-group">
        <div class="form-control" :class="{ 'is-invalid': submitted && $v.form.first_name.$error }">
          <input type="text" v-model="form.first_name" id="firstname" placeholder="Förnamn *" />
          <label for="firstname"> Förnamn <span class="required"> *</span> </label>
        </div>
        <div class="form-control" :class="{ 'is-invalid': submitted && $v.form.last_name.$error }">
          <input type="text" v-model="form.last_name" id="lastname" placeholder="Efternamn *" />
          <label for="lastname"> Efternamn <span class="required"> *</span> </label>
        </div>
      </div>
      <div class="input-group">
        <div class="form-control" :class="{ 'is-invalid': submitted && $v.form.phone.$error }">
          <input type="tel" v-model="form.phone" id="mobile" placeholder="Telefonnummer *" />
          <label for="mobile"> Telefonnummer <span class="required"> *</span> </label>
        </div>
        <div class="form-control" :class="{ 'is-invalid': submitted && $v.form.email.$error }">
          <input type="email" v-model="form.email" id="email" placeholder="E-post *" />
          <label for="email">
            E-post <span class="required"> *</span>
            <span v-if="submitted && !$v.form.email.email" class="error-msg">Please provide valid email</span>
          </label>
        </div>
      </div>
    </fieldset>

    <fieldset class="models" v-if="models.length > 1">
      <legend>1. Välj modell</legend>
      <template v-for="(model, index) in models">
        <input
          type="radio"
          name="model"
          :id="'model_' + index"
          :key="'model_input_' + index"
          :value="model.agCode"
          v-model="form.ag_model_code" />
        <label class="model" :key="'model_label' + index" :for="'model_' + index">
          <span class="name">{{ model.name }}</span>
          <span class="description">{{ model.description }}</span>
          <img :src="model.img" alt="" />
        </label>
      </template>
    </fieldset>
    <fieldset class="days" :class="{ disabled: form.ag_model_code == null }">
      <legend>2. Välj dag</legend>

      <div class="options">
        <button class="previous" type="button" @click="decreaseStartDate()"></button>
        <ul class="days">
          <li v-for="(date, index) in dates" :key="index">
            <input
              type="radio"
              name="date"
              :id="'date_' + index"
              :value="date"
              v-model="selectedDate"
              :disabled="date.getDay() == 0" />
            <label :for="'date_' + index">
              <span class="weekday">{{ DateFormatter.format(date, 'DDD') }}</span>
              <span class="date">{{ DateFormatter.format(date, 'DD.MM') }}</span>
            </label>
          </li>
        </ul>
        <button class="next" type="button" @click="increaseStartDate()"></button>
      </div>
    </fieldset>
    <fieldset class="slots" :class="{ disabled: selectedDate == null }">
      <legend>3. Välj tid</legend>
      <div class="options">
        <button v-if="shownTimes < maxTimes" class="previous" type="button" @click="decreaseStartTime()"></button>
        <ul class="slots">
          <li
            v-for="(time, index) in times(selectedDate)"
            :key="index"
            @click="selectSlot(time)"
            :class="{
              available: isSlotAvaialble(time),
              selected: isSlotSelected(time),
            }">
            {{ DateFormatter.format(time, 'HH:mm') }}
          </li>
        </ul>
        <button v-if="shownTimes < maxTimes" class="next" type="button" @click="increaseStartTime()"></button>
      </div>
      <p class="days-text">
        Hittar du ingen ledig tid eller en tid som passar dig? Så finns möjligheten till drop-in (kl 10.30 - 16.30,
        mån-lör).
      </p>
    </fieldset>
    <fieldset class="extra" :class="{ disabled: selectedSlot == null }">
      <legend>4. Busstransfer</legend>
      <div class="background">
        <input type="checkbox" name="bus" id="bus" value="1" v-model="form.bus" />
        <label for="bus">Jag önskar busstransfer</label>
        <p>OBS: Bussen avgår från hotell Åregården (vid Åre torg) 15min innan din bokade tid</p>
      </div>
    </fieldset>

    <div class="button-container" v-if="!success">
      <div
        class="error-text"
        v-if="
          submitted &&
          ($v.form.first_name.$error || $v.form.last_name.$error || $v.form.phone.$error || $v.form.email.$error)
        ">
        Vänligen fyll i formuläret. Alla fält är obligatoriska.
      </div>
      <input type="submit" value="Boka" :class="{ disabled: selectedSlot == null }" />
    </div>

    <div class="success" v-else>
      <div class="success-base">
        Du har nu bokat provkörning!<br />
        Ett bekräftelse SMS kommer inom kort.
      </div>
      <div class="success-extra">
        Om du måste boka av din Provkörning, vänligen SMS:a AVBOKA + ditt namn den tid du vill boka av till 076 113 68 09.
      </div>
      <div class="success-redirect">
        <p class="success-redirect-title">
          Du kommer att omdirigeras till audi.se om <span class="success-redirect-title-number">{{ timer }}</span>
        </p>
      </div>
    </div>
  </form>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import DateFormatter from '../dateFormatter';
const initialFormData = {
  first_name: '',
  last_name: '',
  phone: '',
  email: '',
  start: '',
  comment: null,
  bus: null,
  ag_model_code: null,
  per_day: 40,
  duration: 30,
  days: 2,
};
const availableTimes = ['10:00', '11:00', '12:00', '13:30', '14:30', '15:30', '16:30'];

export default {
  name: 'Register',
  mixins: [validationMixin],
  components: {},
  mounted() {
    let today = new Date();
    if (today > this.minDate) {
      this.minDate.setDate(today.getDate());
      this.minDate.setMonth(today.getMonth());
      this.minDate.setFullYear(today.getFullYear());
      this.startDate.setDate(today.getDate());
      this.startDate.setMonth(today.getMonth());
      this.startDate.setFullYear(today.getFullYear());
    }
    this.adjustTimeDisplay();
    window.addEventListener('resize', this.adjustTimeDisplay);
    if (this.models.length == 1) {
      initialFormData.ag_model_code = this.models[0].agCode;
    }
  },

  data() {
    return {
      form: Object.assign({}, initialFormData),
      slots: {},
      currentSlots: [],
      selectedDate: null,
      selectedSlot: null,
      minDate: new Date(2024, 1, 12, 10, 0, 0),
      maxDate: new Date(2024, 2, 9, 10, 0, 0),
      startDate: new Date(2024, 1, 12, 10, 0, 0),
      startTime: 0,
      shownDates: 0,
      shownTimes: 0,
      maxTimes: 12,
      maxDates: 7,
      submitted: false,
      success: false,
      DateFormatter: DateFormatter,
      models: [
        {
          name: 'Q8 e-tron',
          img: require('@/assets/q8.jpg'),
          code: 'ZZZG',
          agCode: 'ZZZGE',
          description: 'Körning i Parkourbanan ',
        },
        {
          name: 'Q4 e-tron',
          img: require('@/assets/q4.jpg'),
          code: 'ZZZF',
          description: 'Körning på allmän väg',
          agCode: 'ZZZFZ',
        },
      ],
      timer: 10,
    };
  },
  computed: {
    dates() {
      let dates = [];
      for (let i = 0; i < this.shownDates; i++) {
        let date = new Date(this.startDate);
        date.setDate(date.getDate() + i);
        dates.push(date);
      }
      return dates;
    },
  },
  watch: {
    selectedDate(value) {
      console.log(value, 'value');
      if (value) {
        this.dayClicked(value);
      }
    },
    'form.ag_model_code': function () {
      let d = this.selectedDate;
      this.selectedDate = null;
      this.$nextTick(() => {
        this.selectedDate = d;
      });
    },
  },
  validations: {
    form: {
      first_name: {
        required,
      },
      last_name: {
        required,
      },
      phone: {
        required,
      },
      email: {
        required,
        email,
      },
    },
  },
  methods: {
    adjustTimeDisplay() {
      var w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      if (w < 720) {
        this.shownTimes = 3;
        this.shownDates = 3;
      } else {
        this.shownTimes = this.maxTimes;
        this.shownDates = this.maxDates;
      }
      let maxShownDate = new Date(this.maxDate);
      maxShownDate.setDate(maxShownDate.getDate() - (this.shownDates - 1));
      while (this.startDate > maxShownDate) {
        this.startDate.setDate(this.startDate.getDate() - 1);
        this.startDate = new Date(this.startDate);
      }
    },
    isTimeAvailable(date) {
      return availableTimes.includes(DateFormatter.format(date, 'HH:mm'));
    },
    times(date) {
      date = date || this.startDate;
      let times = [];
      for (let i = this.startTime / initialFormData.duration; ; i++) {
        let d = new Date(date);
        d.setMinutes(d.getMinutes() + initialFormData.duration * i);
        if (this.isTimeAvailable(d)) {
          times.push(d);
        }
        if (
          !(
            times.length < this.maxTimes && times.length - this.startTime / initialFormData.duration < this.shownTimes
          ) ||
          date.getDate() != d.getDate() ||
          times.length >= this.shownTimes
        ) {
          break;
        }
      }
      return times;
    },

    increaseStartTime() {
      while (this.startTime / initialFormData.duration < 20) {
        this.startTime += initialFormData.duration;
        let times = this.times(this.startDate);
        if (times.length < this.shownTimes) {
          console.log(times, this.shownTimes);
          this.startTime -= initialFormData.duration;
          return;
        }

        console.log(this.startTime);
        let d = new Date(this.startDate);
        d.setMinutes(d.getMinutes() + this.startTime);
        if (this.isTimeAvailable(d)) {
          return;
        }
      }
    },
    decreaseStartTime() {
      while (this.startTime > 0) {
        this.startTime -= initialFormData.duration;
        console.log(this.startTime);
        let d = new Date(this.startDate);
        d.setMinutes(d.getMinutes() + this.startTime);
        if (this.isTimeAvailable(d)) {
          return;
        }
      }
    },
    increaseStartDate() {
      let maxShownDate = new Date(this.maxDate);
      maxShownDate.setDate(maxShownDate.getDate() - (this.shownDates - 1));
      if (this.startDate >= maxShownDate) {
        return;
      }
      this.startDate.setDate(this.startDate.getDate() + 1);
      this.startDate = new Date(this.startDate);
    },
    decreaseStartDate() {
      if (this.startDate <= this.minDate) {
        return;
      }
      this.startDate.setDate(this.startDate.getDate() - 1);
      this.startDate = new Date(this.startDate);
    },
    fetchData(date, ag_model_code) {
      date = new Date(date);
      date.setDate(date.getDate() - 1);
      return window.axios
        .post('/api/slots', {
          per_day: initialFormData.per_day,
          duration: initialFormData.duration,
          days: initialFormData.days,
          start: date.toISOString(),
          ag_model_code: ag_model_code,
        })
        .then((response) => {
          if (response.data && response.data.slots) {
            for (let slot in response.data.slots) {
              for (let i = 0; i < response.data.slots[slot].length; i++) {
                let d = new Date(Date.parse(response.data.slots[slot][i]));
                response.data.slots[slot][i] = DateFormatter.format(d, 'YYYY-MM-DDTHH:mm:ssZ');
              }
            }
            this.slots = response.data.slots;
          }
        })
        .catch((error) => {
          this.slots = {};
          console.log(error);
        });
    },
    isSlotAvaialble(time) {
      for (let i = 0; i < this.currentSlots.length; i++) {
        let slot = this.currentSlots[i];
        if (slot.includes(DateFormatter.format(time, 'HH:mm'))) {
          console.log(slot, DateFormatter.format(time, 'HH:mm'));

          return true;
        }
      }
      return false;
    },
    isSlotSelected(time) {
      return (
        DateFormatter.format(this.selectedSlot, 'YYYY-MM-DD HH:mm') == DateFormatter.format(time, 'YYYY-MM-DD HH:mm')
      );
    },
    dayClicked(date) {
      this.currentSlots = [];
      let isoFormat = DateFormatter.format(date, 'YYYY-MM-DD');
      if (isoFormat) {
        this.fetchData(date, this.form.ag_model_code).then(() => {
          if (this.slots[isoFormat]) {
            this.currentSlots = this.slots[isoFormat];
          }
        });
      }
    },
    getIsoFormat(date) {
      let splitDate = date.split('/');
      if (splitDate.length > 2) {
        let year = splitDate[2];
        let month = splitDate[1];
        let day = splitDate[0];
        if (month < 10) {
          month = '0' + month;
        }
        if (day < 10) {
          day = '0' + day;
        }
        return year + '-' + month + '-' + day;
      }
      return false;
    },
    selectSlot(time) {
      if (!this.isSlotAvaialble(time)) {
        return false;
      }
      this.selectedSlot = time;
      this.form.start = time.toISOString();
      return true;
    },

    validationStatus(validation) {
      return typeof validation != 'undefined' ? validation.$error : false;
    },

    submitForm() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      if (this.form.bus) {
        this.form.comment = 'Bus ride requested';
      }

      window.axios
        .post('/api/reserve', this.form)
        .then((response) => {
          this.submitted = false;
          this.success = true;
          /*
          
          this.form = Object.assign({}, initialFormData);
          this.selectedSlot = null;
          this.selectedDate = null;
          */
          console.log(response);
          let interval = setInterval(() => {
            this.timer--;
            if (this.timer == 0) {
              clearInterval(interval);
              window.location.href = 'https://www.audi.se';
            }
          }, 1000);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
$primaryColor: #000;
$secondaryColor: #fff;
$textColor: #333;

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.registration {
  width: 100%;
  max-width: 144rem;
  margin: 0 auto;
  padding: 6rem 0;
  display: flex;
  flex-direction: column;
  gap: 4rem;

  .form-title {
    font-weight: 400;
    font-size: 36px;
    line-height: 100%;
    color: $primaryColor;
    text-align: left;
    padding: 0 4rem;
    font-family: 'AudiExtended';
  }
  .form-sub-title {
    font-weight: 400;
    font-size: 28px;
    line-height: 100%;
    color: $primaryColor;
    text-align: left;
    padding: 0 4rem;
    padding-bottom: 2rem;
    font-family: 'AudiExtended';
  }
}

fieldset {
  border: 0;
  padding: 0;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;

    &.slots ul li:not(.available) {
      opacity: 1;
    }
  }

  legend {
    font-size: 1.8rem;
    font-weight: bold;
    text-align: left;
    margin-bottom: 3rem;
    margin-left: 4rem;
    font-family: 'AudiExtended';
    .details {
      margin-left: 1em;
      font-size: 1.4rem;
    }
  }

  &.inputs {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 2rem;
    .input-group {
      width: 100%;
      display: flex;
      column-gap: 6rem;
      max-width: 96rem;
      padding: 0 4rem;
      margin-bottom: 3.8rem;
      margin-top: -1rem;
      &:last-child {
        margin-top: 0;
        margin-bottom: 0;
      }
      .form-control {
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-start;
        height: 6rem;
        input {
          background-color: transparent;
          border: 0;
          border-bottom: 1px solid #666;
          outline: none;
          font-weight: 400;
          font-size: 18px;
          line-height: 130%;
          padding-bottom: 1rem;
          color: #333;
          width: 100%;
          font-family: 'AudiWide';

          &:focus,
          &:hover {
            border-bottom: 1px solid #333;
          }
        }
        label {
          font-weight: normal;
          font-size: 18px;
          line-height: 130%;
          color: #666666;
          font-family: 'AudiWide';
        }

        input:placeholder-shown + label {
          transform: translate(0, 2.75rem) scale(1);
        }

        input:focus + label {
          font-weight: 400;
          font-size: 14px;
          line-height: 100%;
          color: #333333;
        }

        ::-webkit-input-placeholder {
          opacity: 0;
          transition: inherit;
        }
        input:not(:placeholder-shown) + label {
          font-weight: 400;
          font-size: 14px;
          line-height: 100%;
          color: #333333;
        }

        input:not(:placeholder-shown) + label,
        input:focus + label {
          transform: translate(0, -0.5rem) scale(1);
          //   cursor: pointer;
        }
        label,
        input {
          transition: all 0.2s;
          touch-action: manipulation;
        }
        &.is-invalid {
          label {
            color: #f50537;
          }
          input {
            border-bottom: 1px solid #f50537;
          }
          input:focus + label {
            color: #f50537;
          }
          input:not(:placeholder-shown) + label {
            color: #f50537;
          }
        }
      }
    }
  }
  &.models {
    display: flex;
    column-gap: 0.3rem;
    row-gap: 0.3rem;

    input[type='radio'] {
      width: 0;
      height: 0;
      position: absolute;
      left: -99999;
      opacity: 0;

      &:checked + label {
        .name {
          font-weight: 700;
        }

        border-bottom: 1rem solid #000;
      }
    }

    label {
      background-color: #fff;
      flex-shrink: 1;
      margin: 0;
      text-align: left;
      border-bottom: 1rem solid #fff;
      cursor: pointer;
      width: 100%;
      &:hover {
        opacity: 0.7;
      }

      .name {
        display: block;
        padding: 2.5rem 2.5rem 0.5rem 2.5rem;
        font-weight: 400;
        font-size: 48px;
        line-height: 100%;
        font-family: 'AudiExtended';
      }

      .description {
        font-size: 1.8rem;
        padding: 0rem 3rem 0;
        line-height: 23.4px;
      }

      img {
        width: 100%;
        display: block;
      }
    }
  }
}

fieldset.days,
fieldset.slots {
  .options {
    display: flex;

    .next,
    .previous {
      height: 100%;
      width: 4rem;
      height: 7.8rem;
      box-sizing: border-box;
      background-color: #666;
      border: 0;
      background-repeat: no-repeat;
      background-position: center center;
      transition: background-color 0.2s ease-in-out;

      &:hover {
        background-color: #000;
        cursor: pointer;
      }
    }

    .next {
      background-image: url('~@/assets/next.svg');
    }

    .previous {
      background-image: url('~@/assets/prev.svg');
    }

    ul {
      flex-grow: 1;
    }
  }

  p {
    margin: 1rem 0 0;
    font-size: 1.6rem;
  }
}

fieldset.days {
  input[type='radio'] {
    width: 0;
    height: 0;
    position: absolute;
    left: -99999;
    opacity: 0;
  }

  ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    flex-grow: 1;
    column-gap: 0.3rem;

    li {
      flex-grow: 1;

      label {
        cursor: pointer;
        display: block;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: #fff;
        height: 7.8rem;
        .weekday {
          color: #666;
          font-size: 1.2rem;
          margin-bottom: 1rem;
          line-height: 100%;
          font-family: 'AudiWide';
        }

        .date {
          font-size: 1.8rem;
          line-height: 100%;
          font-family: 'AudiExtended';
        }

        &:hover {
          opacity: 0.7;
        }
      }

      input:checked + label {
        background-color: $primaryColor;
        color: $secondaryColor;
        .weekday,
        .date {
          font-weight: 700;
        }

        .weekday {
          color: $secondaryColor;
        }
      }

      input:disabled + label {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }
}

fieldset.slots {
  ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
    column-gap: 0.3rem;
    row-gap: 0.3rem;

    li {
      cursor: pointer;
      height: 7.8rem;
      background-color: #fff;
      flex-grow: 1;
      flex-basis: 12%;
      font-size: 1.8rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'AudiExtended';

      &:not(.available) {
        opacity: 0.5;
        cursor: not-allowed;
      }

      &.selected {
        background-color: $primaryColor;
        color: $secondaryColor;
        font-weight: 700;
      }

      &:hover {
        opacity: 0.7;
      }
    }
  }
  .days-text {
    font-size: 1.8rem;
    line-height: 2.7rem;
    width: 100%;
    font-family: 'AudiWide';
    text-align: center;
    font-weight: 700;
    padding: 0 2rem;
  }
}

fieldset.extra {
  .background {
    background: #fff;
    padding: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 3rem;
  }

  input[type='checkbox'] {
    width: 0;
    height: 0;
    position: absolute;
    left: -99999;
    opacity: 0;

    & + label {
      display: inline-block;
      padding-left: 4.4rem;
      font-size: 2.4rem;
      font-family: 'AudiExtended';
      background-image: url('~@/assets/checkbox.svg');
      background-position: left center;
      background-repeat: no-repeat;
      background-size: 2.4rem;
      text-align: left;
      cursor: pointer;
    }

    &:checked + label {
      background-image: url('~@/assets/checkbox-checked.svg');
    }
  }

  p {
    font-size: 1.4rem;
  }
}

.button-container {
  width: 100%;
  position: relative;
  .error-text {
    font-weight: 400;
    font-size: 18px;
    line-height: 130%;
    color: #f50537;
    text-align: center;
    position: absolute;
    top: 0rem;
    left: 50%;
    transform: translateX(-50%);
    font-family: 'AudiWide';
    width: 100%;
  }
}

input[type='submit'] {
  font-size: 1.8rem;
  line-height: 1.8rem;
  padding: 1rem;
  border: 0;
  background: $primaryColor;
  color: $secondaryColor;
  width: 32rem;
  max-width: 100%;
  box-sizing: border-box;
  margin: 4rem auto 6rem auto;
  font-family: 'AudiWide';
  cursor: pointer;
  height: 43px;
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &:hover {
    opacity: 0.7;
  }
}

.success {
  padding: 0 4rem;
  .success-base {
    height: 4rem;
    padding-left: 5.8rem;
    background-size: 3.8rem;
    background-image: url('~@/assets/confirm.svg');
    background-repeat: no-repeat;
    background-position: left center;
    display: inline-block;
    margin: 0 auto;
    font-size: 1.8rem;
    line-height: 1.9rem;
    text-align: left;
    font-family: 'AudiWide';
  }

  .success-extra {
    margin-top: 2rem;
    font-size: 1.8rem;
    margin-bottom: 2rem;
    font-weight: 700;
    font-family: 'AudiWide';
  }

  .success-redirect {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 2rem 0;
    gap: 10px;
    .success-redirect-title {
      font-weight: 400;
      font-size: 18px;
      line-height: 110%;
      color: $primaryColor;
      font-family: 'AudiWide';
      .success-redirect-title-number {
        font-weight: 400;
        font-size: 18px;
        line-height: 110%;
        color: $primaryColor;
        font-family: 'AudiWide';
        width: 24px;
        text-align: left;
      }
    }
    .success-redirect-text {
      font-size: 1.8rem;
      font-weight: bold;
      line-height: 100%;
      color: $primaryColor;
      font-family: 'AudiWide';
    }
  }
}

@media (max-width: 719px) {
  .registration {
    padding: 3rem 0rem;
    .form-title {
      font-size: 24px;
      padding: 0 2rem;
    }
    .form-sub-title {
      padding: 0 2rem;
      padding-bottom: 2rem;
      font-size: 24px;
    }
  }

  fieldset {
    legend {
      font-size: 18px;
      margin-left: 2rem;
      margin-bottom: 2rem;
    }
    &.inputs {
      flex-direction: column;
      .input-group {
        flex-direction: column;
        gap: 3rem;
        max-width: 36rem;
        padding: 0 2rem;
        margin: 0 auto;
        margin-bottom: 3rem;
      }
    }
    &.models {
      flex-direction: column;
      column-gap: 0.3rem;
      row-gap: 1em;
      label {
        width: calc(100% - 4rem);
        margin: 0 auto;
        .name {
          font-size: 2.4rem;
        }
        .description {
          font-size: 1.6rem;
          padding: 0 2.5rem;
        }

        img {
          height: 130px;
          object-fit: contain;
          width: 100%;
          margin: 0 auto;
        }
      }
    }
    &.days,
    &.slots {
      .next,
      .previous {
        height: 7.8rem;
      }
      .options {
        gap: 0.3rem;
      }
    }
  }

  fieldset.extra {
    .background {
      width: calc(100% - 4rem);
      padding: 2.5rem 4rem;
      margin: 0 auto;
      gap: 2rem;
      input[type='checkbox'] {
        & + label {
          font-size: 1.8rem;
        }
      }
    }
  }
  .success {
    padding: 0 2rem;
  }
  .button-container {
    .error-text {
      top: -1rem;
    }
  }
}
</style>
