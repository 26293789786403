<template>
  <div id="app">
    <Register />
  </div>
</template>

<script>
import Register from './components/Register.vue';

export default {
  name: 'App',
  components: {
    Register,
  },
};
</script>

<style>
html,
body {
  margin: 0;
  font-size: 10px;
  min-height: 175rem;
  background-color: #f2f2f2;
}
@font-face {
  font-family: 'AudiType';
  src: url('./fonts/AudiType-Bold_4.03.ttf');
  font-style: bold;
}
@font-face {
  font-family: 'AudiType';
  src: url('./fonts/AudiType-Normal_4.03.ttf');
  font-style: normal;
}

@font-face {
  font-family: 'AudiExtended';
  src: url('./fonts/AudiType-ExtendedBold_4.03.ttf');
  font-style: bold;
}
@font-face {
  font-family: 'AudiExtended';
  src: url('./fonts/AudiType-ExtendedNormal_4.03.ttf');
  font-style: normal;
}

@font-face {
  font-family: 'AudiWide';
  src: url('./fonts/AudiType-WideBold_4.03.ttf');
  font-style: bold;
}
@font-face {
  font-family: 'AudiWide';
  src: url('./fonts/AudiType-WideNormal_4.03.ttf');
  font-style: normal;
}

@font-face {
  font-family: 'AudiWide';
  src: url('./fonts/AudiType-WideLight_4.03.ttf');
  font-style: light;
}

#app {
  font-family: 'AudiType', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000000;
}
</style>
