class DateFormatter {
    static formats = {
        dateTime: "D.MMM H:m",
        dateFull: "D.M. YYYY",
        dateNoYear: "D.M",
        dateNoYearDay: "DDD, D.M",
        time: "H:m",
        timeZero: "HH:m",
        ISO: "YYYY-MM-DDTHH:mm"
    };
    static format(value, format) {
        format = format || this.formats.dateTime;
        if (!(value instanceof Date && value.getTime())) {
            value = Date.parse(value);
        }
        if (!value) {
            return '';
        }
        const days = ["Söndag", "Måndag", "Tisdag", "Onsdag", "Torsdag", "Fredag", "Lördag"],
            months = [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
            ];
        const year = value.getFullYear();
        const month = value.getMonth() + 1;
        const date = value.getDate();
        const monthName = months[value.getMonth()];
        const dayName = days[value.getDay()];
        const hour = value.getHours();
        const minute = value.getMinutes();
        const second = value.getSeconds();
        let timezone = -parseInt(value.getTimezoneOffset() / 60);
        if (timezone < -9) {
            timezone = timezone + ':00';
        } else if (timezone < 0) {
            timezone = '-0' + (-timezone) + ':00';
        } else if (timezone < 10) {
            timezone = '+0' + timezone + ':00';
        } else {
            timezone = '+' + timezone + ':00';
        }
        const map = {
            YYYY: year,
            MMM: monthName,
            MM: ("0" + month).slice(-2),
            M: month,
            DDD: dayName,
            DD: ("0" + date).slice(-2),
            D: date,
            HH: ("0" + hour).slice(-2),
            H: hour,
            mm: ("0" + minute).slice(-2),
            m: ("0" + minute).slice(-2),
            ss: ("0" + second).slice(-2),
            s: ("0" + second).slice(-2),
            Z: timezone
        };
        return format.replace(/Y+|M+|D+|H+|m+|s+|Z+/g, function (str) {
            return map[str];
        });
    }
}


export default DateFormatter;